import { useCallback, useEffect, useState } from "react";
import { Paper, Box, List, ListItem, ListItemText, CircularProgress } from "@mui/material";

import { TextField, InputAdornment, IconButton, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import API, { SYNAPSE_API } from "../../api/Api";
import { useAuth } from "../../context/authContext/Auth";
import axios from "axios";
import { useLocation, useNavigate, } from 'react-router-dom';




const SearchBox = styled(TextField)(({ theme, expanded }) => ({
  transition: 'width 0.3s ease-in-out',
  width: expanded ? `calc(90vw - 6.31px)` : '310px',
  paddingLeft: expanded ? '2%' : '0px',
  height: expanded ? '54px' : '54px',
  margin: 'auto',
  boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px',
  backgroundColor: 'red',

  [theme.breakpoints.down('sm')]: {
    width: expanded ? '100%' : '250px',
  },
  backgroundColor: 'white',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
  },
}));

function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

const SearchInput = ({ expanded, handleClose, reportData = {} }) => {
  const [inputValue, setInputValue] = useState('');
  const { config } = useAuth();
  const { Base_URL } = API;
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [noData, setNoData] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()

  const handleInputClose = () => {
    handleClose()
    setInputValue('')
    setData([])
    setNoData(false)
    window.scroll({
      top: '470'
    })
  };

  function handleNavigate(item) {

    let url = '';
    if (item.is_library) {
      // navigate(`/reports/reports-library/${item.slug}`)
      url = `/reports/reports-library/${item.slug}`;

    } else if (location.pathname.includes('/hubs')) {

      const segments = location.pathname.split('/');
      segments[segments.length - 1] = item.slug;
      // const newPath = segments.join('/');
      // navigate(newPath);
      url = segments.join('/');

    } else {
      // navigate(`/reports/${item.slug}`)
      url = `/reports/${item.slug}`;
    }
    window.open(url, '_blank');
    handleInputClose()
  }

  const fetchData = async (query, report) => {
    setLoading(true)
    setData([])
    setNoData(false)
    const bodyParameters = {
      search: query,
      from_catalogue: report?.isReportFlash ? true : false,
      from_library: report?.is_library ? true : false,
      from_hub_with_id: report?.hub_id ? report.hub_id : null
    }
    try {
      const response = await SYNAPSE_API.post(`/api/global-reports/search`, bodyParameters);
      setData(response.data.reports)
      setNoData(response.data.reports.length < 1 ? true : false)
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setLoading(false)
    }
  };

  const debouncedFetchData = useCallback(debounce(fetchData, 1500), []);
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (newValue.length > 1) {
      debouncedFetchData(newValue, reportData);
    } else {
      setData([])
    }
  };

  return (<>
    <Box display="flex"
      justifyContent="end"
      mt={'-64px'}
      pt={'10px'}
      backgroundColor="white"
      // width={'100%'}
      position={'relative'}
      mx={'auto'}
      // border={expanded ? '1px solid #189cde' : "none"}
      // borderRadius={'10px'}
      overflow={'hidden'}
      onClick={() => {
        if (!expanded) {
          handleInputClose()
        }
      }}
    >

      <SearchBox
        variant="outlined"
        expanded={expanded}
        placeholder="Find a new report"
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleInputClose}>
                {!expanded ? <SearchIcon /> : <CloseIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

    </Box>
    {
      expanded && inputValue.length > 1 && (
        <Box sx={{
          position: 'absolute',
          top: '100%',
          width: '90%',
          maxHeight: '400px',
          overflowY: 'auto',
          py: '0px',
          mx: '5%',
          scrollbarWidth: 'none',
          backgroundColor: 'white',
          boxShadow: 1,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px'
        }} >
          {noData && <h3 style={{ height: '100%', textAlign: 'center', marginTop: '40px', paddingBottom: '20px' }}>No data found</h3>}
          {!loading ? <List sx={{ py: '0px' }}>
            {data?.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => handleNavigate(item)}
                sx={{
                  cursor: "pointer",
                  //  pt: '3px', pb: '3px',
                  pl: '3%',
                  //  borderRadius: '10px',
                  "&:hover": {
                    //  color: 'blue'
                    backgroundColor: '#f0f7fc',
                    color: '#449ac6',
                    // borderLeft: 'red 4px solid',
                    width: '98%',
                    borderTopRightRadius: '48px',
                    borderBottomRightRadius: '48px'
                  }
                }}
              >
                <ListItemText sx={{ textTransform: 'uppercase' }} primary={item.title} />
              </ListItem>
            ))}
          </List> : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )
    }
  </>
  );
};

export default SearchInput