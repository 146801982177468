export const createTags = (modalFilter) => {
  if (
    !modalFilter.selectedCountry.length &&
    !modalFilter.selectedRegion.length
  ) {
    return [];
  }

  return [
    ...modalFilter.selectedRegion.map((tag) => ({
      name: tag,
      from: "REGION",
      id: `${tag}-REGION`,
    })),
    ...modalFilter.selectedCountry.map((tag) => ({
      name: tag,
      from: "COUNTRY",
      id: `${tag}-COUNTRY`,
    })),
  ];
};

export const createFilterQuery = (modalFilter) => {
  return {
    regions: modalFilter.selectedRegion,
    countries: modalFilter.selectedCountry,
  };
};

export const removeFilter = ({ tag, filterFrom }, currentFilters) => {
  const data = {};
  switch (filterFrom) {
    case "REGION":
      data.selectedRegion = currentFilters.selectedRegion.filter(
        (filter) => filter !== tag
      );
      break;
    case "COUNTRY":
      data.selectedCountry = currentFilters.selectedCountry.filter(
        (filter) => filter !== tag
      );
      break;
    default:
      break;
  }
  return data;
};

export const getTabBasedOnLibrary = (tab, isLibraryReports) => {
  if(tab === "MY_REPORTS") {
    return isLibraryReports ? "REPORTS_LIBRARY" : "MY_REPORTS"
  }
  return tab;
}

export const getCountForLibrary = (tab, isLibraryReports) => {
  if(tab === "MY_REPORTS" && isLibraryReports) {
    return "my_reports_count"
  }else if(tab === "REPORTS_LIBRARY" && isLibraryReports){
    return "my_reports_count"
  }
  return "achieve_reports_count"
}

export const onLoadStateChangeDueToParams = (getParams, dispatch, state) => {

  if(getParams("page") && getParams("limit")) {
    const [page, limit] = [parseInt(getParams("page")), parseInt(getParams("limit"))];
    if(!page || !limit) return;
    dispatch({
      type: "SET_PAGINATION",
      payload: { page, limit },
    });
    state = {
      ...state,
      page,
      limit
    }
  }
  
  if(getParams("tab") === "MY_REPORTS") {
    if(getParams("isCommissionedReports") === "true") {
      dispatch({
        type: "SET_IS_COMMISSIONED_REPORTS",
        payload: true,
      });
      state = {
        ...state,
        isCommissionedReports: true
      }
    }
  
    if(getParams("isLibraryReports") === "true") {
      dispatch({
        type: "SET_IS_LIBRARY_REPORTS",
        payload: true,
      });
      state = {
        ...state,
        isLibraryReports: true
      }
    }

    if(getParams("onlyHubReportsForMyReports") === "true") {
      dispatch({
        type: "SET_ONLY_HUB_REPORTS_FOR_MY_REPORTS",
        payload: true,
      });
      state = {
        ...state,
        onlyHubReportsForMyReports: true
      }
    }
  }

  if(getParams("tab") === "CATALOGUE") {
    if(getParams("catalogueReportStatusFilter")) {
      dispatch({
        type: "SET_CATALOGUE_REPORT_STATUS_FILTER",
        payload: getParams("catalogueReportStatusFilter"),
      });
      state = {
        ...state,
        catalogueReportStatusFilter: getParams("catalogueReportStatusFilter")
      }
    }
  }

  if(getParams("tab") === "REPORTS_LIBRARY") {
    dispatch({
      type: "SET_IS_LIBRARY_HUB_REPORTS",
      payload: getParams("isLibraryHubReport") === null ? 'hub_and_library_reports' : getParams("isLibraryHubReport"),
    });
  }

  return state;
}

export const updateParamsOnManualChanges = (searchParams, state) => {
  searchParams((p) => {
    p.set("isLibraryReports", state.isLibraryReports);
    p.set("isCommissionedReports", state.isCommissionedReports);
    p.set("onlyHubReportsForMyReports", state.onlyHubReportsForMyReports);
    p.set("catalogueReportStatusFilter", state.catalogueReportStatusFilter);
    p.set("isLibraryHubReport", state.isLibraryHubReport);
    p.set("page", 1);
    p.set("limit", 10);
    return p;
  });
}

export const cannotShowForThisUser = (user) => {
  if(user === null) return true 
  if(user.email === 'abdulmalik.syed1@dominos.com') {
    return true
  }
  if(user.custom_user === true) {
    return true
  }
  return false
}